<template>
  <p class="i-title">
    <slot />
  </p>
</template>

<script>
import './i-title.scss';

export default {
  name: 'Title',
};
</script>
